import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Paper, Grid, Tooltip, InputAdornment, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { DateRange, CalendarToday } from '@mui/icons-material';
import { format, isValid } from 'date-fns';
import { FormSubmission } from '../../../services/firebase/submissionReader';
import { useTheme } from '@mui/material/styles';

interface TotalDaysVisualizationProps {
  submissions: FormSubmission[];
  questionId?: string; // Optional specific questionId
  onFilterChange?: (questionId: string, email: string | null) => void;
  activeFilter?: string | null;
}

// Define an interface for date range objects
interface DateRangeObject {
  startDate?: any;
  endDate?: any;
  start?: any;
  end?: any;
  useHours?: boolean;
  hours?: number;
  includeWeekends?: boolean;
}

// Type guard function to check if an object is a date range
function isDateRange(obj: any): obj is DateRangeObject {
  return obj !== null && 
         typeof obj === 'object' &&
         (
           (('startDate' in obj) && ('endDate' in obj)) ||
           (('start' in obj) && ('end' in obj))
         );
}

// Helper to safely convert Firebase timestamp to Date
const convertToDate = (timestamp: any): Date | null => {
  if (!timestamp) return null;
  
  try {
    // Handle Firestore timestamp with toDate method
    if (typeof timestamp.toDate === 'function') {
      return timestamp.toDate();
    }
    
    // Handle serialized Firestore timestamp with seconds
    if (timestamp.seconds !== undefined) {
      return new Date(timestamp.seconds * 1000);
    }
    
    // Handle regular Date object
    if (timestamp instanceof Date) {
      return timestamp;
    }
    
    // Try to parse as date string
    const date = new Date(timestamp);
    return isValid(date) ? date : null;
  } catch (e) {
    console.error("Error converting timestamp to date:", e, timestamp);
    return null;
  }
};

const calculateTotalDaysAndHours = (
  submissions: FormSubmission[], 
  startDateFilter: Date, 
  endDateFilter: Date, 
  questionId?: string
): Record<string, React.ReactNode> => {
  const userDays: Record<string, { days: number, hours: number }> = {};
  let processedCount = 0;

  // Log filter dates for debugging
  console.log(`Filtering by date range: ${startDateFilter.toISOString()} to ${endDateFilter.toISOString()}`);
  
  submissions.forEach(submission => {
    const { email, responses } = submission;
    
    // If a specific questionId is provided, check only that question
    if (questionId && responses && responses[questionId]) {
      processDateRange(responses[questionId], email);
      return;
    }
    
    // If no specific questionId is provided, process all date-range responses
    if (!questionId && responses) {
      let foundDateRange = false;
      
      // For each response, check if it's a date range
      Object.entries(responses).forEach(([respQuestionId, response]) => {
        if (response && isDateRange(response)) {
          processDateRange(response, email);
          foundDateRange = true;
        }
      });
      
      if (foundDateRange) {
        return; // Processed at least one date range for this submission
      }
    }
    
    function processDateRange(dateRange: any, userEmail: string) {
      // Convert to proper dates, handling Firebase timestamps
      const start = convertToDate(dateRange.startDate || dateRange.start);
      const end = convertToDate(dateRange.endDate || dateRange.end);
      
      // Skip if we couldn't properly convert the dates
      if (!start || !end) {
        console.warn('Invalid date range found:', dateRange);
        return;
      }
      
      // Log for debugging
      console.log(`Processing date range for ${userEmail}: ${start.toISOString()} to ${end.toISOString()}`, 
        { useHours: dateRange.useHours, hours: dateRange.hours });
      
      // Filter by date range - only include ranges that overlap with the filter period
      if (end < startDateFilter || start > endDateFilter) {
        console.log(`Skipping date range for ${userEmail} - outside filter period`);
        return;
      }
      
      const diffTime = end.getTime() - start.getTime();
      const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const totalHours = dateRange.useHours ? (dateRange.hours || 0) : Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      
      if (!userDays[userEmail]) {
        userDays[userEmail] = { days: 0, hours: 0 };
      }
      userDays[userEmail].days += days;
      userDays[userEmail].hours += totalHours;
      
      processedCount++;
    }
  });
  
  console.log(`Successfully processed ${processedCount} submissions with date ranges`);

  // Convert to display format
  const result: Record<string, React.ReactNode> = {};
  for (const [email, { days, hours }] of Object.entries(userDays)) {
    // Normalize hours - convert excess hours to days
    const normalizedDays = days + Math.floor(hours / 24);
    const normalizedHours = hours % 24;
    
    // Create a component with styled hours
    result[email] = (
      <>
        {normalizedDays} {normalizedDays === 1 ? 'day' : 'days'}
        {normalizedHours > 0 && (
          <span style={{ 
            fontStyle: 'italic', 
            opacity: 0.8,
            color: 'var(--mui-palette-secondary-main)'
          }}>
            {' '}{normalizedHours} {normalizedHours === 1 ? 'hour' : 'hours'}
          </span>
        )}
      </>
    );
  }
  return result;
};

// Helper to format email for display
const formatEmail = (email: string): string => {
  if (!email) return '';
  
  // If the email contains @ symbol, truncate the domain part
  if (email.includes('@')) {
    const [username, domain] = email.split('@');
    return `${username}@...`;
  }
  
  // If it's not an email or doesn't have @, return as is
  return email;
};

const TotalDaysVisualization: React.FC<TotalDaysVisualizationProps> = ({ 
  submissions,
  questionId
}) => {
  const theme = useTheme();
  
  // Set default filter dates - 1 year ago to current date
  const defaultStartDate = new Date();
  defaultStartDate.setFullYear(defaultStartDate.getFullYear() - 1);
  
  const [startDateFilter, setStartDateFilter] = useState(defaultStartDate);
  const [endDateFilter, setEndDateFilter] = useState(new Date());

  // Calculate user days with the date filter
  const userDays = calculateTotalDaysAndHours(submissions, startDateFilter, endDateFilter, questionId);
  const userCount = Object.keys(userDays).length;

  // Format date for the input field
  const formatDateForInput = (date: Date): string => {
    return format(date, 'yyyy-MM-dd');
  };

  return (
    <Box>
      {/* Date filters - redesigned to be wider with stacked summary box */}
      <Box 
        sx={{ 
          mb: 3, 
          p: 3,
          borderRadius: 1,
          bgcolor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexDirection: 'column', // Always stacked
          alignItems: 'stretch',
          gap: 2.5
        }}
      >
        {/* Date inputs - always full width */}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ mb: 2.5 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'medium' }}>
              Start Date
            </Typography>
            <OutlinedInput
              fullWidth
              id="start-date"
              type="date"
              value={formatDateForInput(startDateFilter)}
              onChange={(e) => {
                const newDate = new Date(e.target.value);
                if (isValid(newDate)) {
                  setStartDateFilter(newDate);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarToday fontSize="small" color="action" />
                </InputAdornment>
              }
              sx={{
                height: '48px',
                '& input': {
                  px: 3,
                  py: 1.5,
                  '&::placeholder': {
                    opacity: 1
                  }
                },
                '& .MuiOutlinedInput-input': {
                  pl: 2
                },
                '& .MuiInputAdornment-root': {
                  ml: 1.5
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'medium' }}>
              End Date
            </Typography>
            <OutlinedInput
              fullWidth
              id="end-date"
              type="date"
              value={formatDateForInput(endDateFilter)}
              onChange={(e) => {
                const newDate = new Date(e.target.value);
                if (isValid(newDate)) {
                  setEndDateFilter(newDate);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarToday fontSize="small" color="action" />
                </InputAdornment>
              }
              sx={{
                height: '48px',
                '& input': {
                  px: 3,
                  py: 1.5,
                  '&::placeholder': {
                    opacity: 1
                  }
                },
                '& .MuiOutlinedInput-input': {
                  pl: 2
                },
                '& .MuiInputAdornment-root': {
                  ml: 1.5
                }
              }}
            />
          </Box>
        </Box>

        {/* Summary box - smaller and stacked underneath */}
        <Box 
          sx={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            px: 2,
            py: 1,
            bgcolor: theme.palette.primary.main + '15',
            borderRadius: 1,
            alignSelf: 'center', // Center horizontally
            maxWidth: '180px', // Limit width
          }}
        >
          <DateRange sx={{ mr: 1, fontSize: '0.9rem', color: theme.palette.primary.main }} />
          <Typography variant="body2" color="primary" fontWeight="medium">
            {userCount} {userCount === 1 ? 'person' : 'people'}
          </Typography>
        </Box>
      </Box>
      
      {/* Results Grid */}
      {userCount > 0 ? (
        <Grid container spacing={1.5}>
          {Object.entries(userDays).map(([email, time]) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={email}>
              <Tooltip title={email} placement="top" arrow>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 2, 
                    bgcolor: 'background.default',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'default',
                    height: '100%',
                    '&:hover': {
                      bgcolor: theme.palette.action.hover,
                      borderColor: theme.palette.primary.light
                    }
                  }}
                >
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      fontWeight: 'medium', 
                      fontSize: '0.9rem', 
                      mb: 0.5,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {formatEmail(email)}
                  </Typography>
                  <Typography 
                    variant="h6" 
                    color="primary" 
                    sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 'bold'
                    }}
                  >
                    {time}
                  </Typography>
                </Paper>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box 
          sx={{ 
            textAlign: 'center', 
            py: 4, 
            border: `1px dashed ${theme.palette.divider}`,
            borderRadius: 1
          }}
        >
          <Typography variant="body1" color="text.secondary">
            No date range data found in the current submissions for this time period
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TotalDaysVisualization;
