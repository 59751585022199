import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  CardHeader, 
  Typography, 
  useTheme, 
  IconButton,
  Collapse 
} from '@mui/material';
import { 
  VisibilityOutlined as VisibilityIcon, 
  VisibilityOffOutlined as VisibilityOffIcon
} from '@mui/icons-material';

import { 
  SelectVisualization, 
  CheckboxVisualization,
  NumberVisualization,
  DateVisualization,
  TimeVisualization,
  TextVisualization,
  RepeatableVisualization,
  SectionVisualization
} from './Visualizations';
import { filterArchivedSubmissions } from '../utils/submissionFilters';

// QuestionSummary props interface
interface QuestionSummaryProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
  onFilterChange?: (questionId: string, filterValue: any) => void;
  allQuestions?: any[];
  includeArchived?: boolean;
  allExpanded?: boolean; // New prop to control expansion from parent
}

// Add this helper function to find section child questions
const findSectionChildQuestions = (sectionId: string, allQuestions: any[], submissions: any[]) => {
  // First, try to find questions that explicitly reference this section
  const childQuestions = allQuestions.filter(q => 
    q.sectionId === sectionId || 
    (q.path && (q.path.includes(sectionId) || q.path.includes(`section_q_${sectionId}`)))
  );
  
  if (childQuestions.length > 0) {
    return childQuestions;
  }
  
  // If no explicit references, look for responses with the section prefix
  if (submissions && submissions.length > 0) {
    const sectionResponsePattern = new RegExp(`^section_q_${sectionId}_(.*)`);
    const childQuestionIds = new Set<string>();
    
    submissions.forEach(submission => {
      if (submission.responses) {
        Object.keys(submission.responses).forEach(key => {
          const match = key.match(sectionResponsePattern);
          if (match && match[1]) {
            childQuestionIds.add(match[1]);
          }
        });
      }
    });
    
    // Check if we found any child question IDs
    if (childQuestionIds.size > 0) {
      // Try to find these questions in the allQuestions array
      const foundQuestions = Array.from(childQuestionIds)
        .map(id => allQuestions.find(q => q.id === id))
        .filter(q => q !== undefined);
      
      if (foundQuestions.length > 0) {
        return foundQuestions;
      }
      
      // If we can't find them, create synthetic questions
      return Array.from(childQuestionIds).map(id => ({
        id,
        label: id.replaceAll('_', ' ').trim(),
        type: 'text', // Default to text type
        sectionId: sectionId
      }));
    }
  }
  
  return [];
};

// Update the QuestionSummary component to handle section questions
const QuestionSummary: React.FC<QuestionSummaryProps> = ({ 
  question, 
  summaryData,
  filteredSubmissions = [],
  onFilterChange,
  allQuestions = [],
  includeArchived = false,
  allExpanded = false // Default to collapsed
}) => {
  const theme = useTheme();
  const [activeFilter, setActiveFilter] = useState<any>(null);
  const [localContentVisible, setLocalContentVisible] = useState(false); // Local state for toggle
  
  // Compute effective visibility based on both local state and parent allExpanded state
  const contentVisible = allExpanded || localContentVisible;
  
  // Filter out archived submissions
  const effectiveSubmissions = React.useMemo(() => 
    filterArchivedSubmissions(filteredSubmissions, includeArchived),
    [filteredSubmissions, includeArchived]
  );
  
  // Update local state when allExpanded changes from parent
  useEffect(() => {
    if (allExpanded && !localContentVisible) {
      // If parent says expand all and we're currently collapsed, sync with parent
      setLocalContentVisible(true);
    } else if (!allExpanded && localContentVisible && !contentVisible) {
      // If parent says collapse all and we were expanded due to local state, collapse
      setLocalContentVisible(false);
    }
  }, [allExpanded, localContentVisible, contentVisible]);
  
  // Add debug logging to check incoming data
  useEffect(() => {
    console.log(`QuestionSummary for ${question.id} (${question.type}):`, {
      hasSummaryData: !!summaryData,
      totalSubmissionsCount: filteredSubmissions.length,
      activeSubmissionsCount: effectiveSubmissions.length,
      allQuestionsCount: allQuestions.length
    });
    
    if (question.type === 'section') {
      const childQuestions = findSectionChildQuestions(question.id, allQuestions, effectiveSubmissions);
      console.log(`Section ${question.id} has ${childQuestions.length} child questions:`, 
        childQuestions.map(q => `${q.id} (${q.type})`));
    }
  }, [question, summaryData, filteredSubmissions, effectiveSubmissions, allQuestions]);
  
  // Handle section type questions specially
  if (question.type === 'section') {
    const childQuestions = findSectionChildQuestions(question.id, allQuestions, effectiveSubmissions);
    
    return (
      <SectionVisualization
        question={question}
        summaryData={summaryData}
        filteredSubmissions={effectiveSubmissions}
        childQuestions={childQuestions}
      />
    );
  }
  
  // Toggle visibility of question content
  const toggleVisibility = () => {
    setLocalContentVisible(!localContentVisible);
  };
  
  // Handle filter changes for this question
  const handleFilterChange = (value: any) => {
    setActiveFilter(value);
    if (onFilterChange) {
      onFilterChange(question.id, value);
    }
  };
  
  // Generate a filter handler for Select visualization
  const handleSelectFilterChange = (index: number, value: any, label: string) => {
    // Extract just the value for simple filtering
    handleFilterChange(value);
  };
  
  return (
    <Card sx={{ mb: 3, overflow: 'hidden', boxShadow: 1, borderRadius: 2 }}>
      <CardHeader
        title={
          <Typography variant="h6" fontWeight="500" color="text.primary">
            {question.label}
          </Typography>
        }
        subheader={
          <Typography variant="body2" color="text.secondary">
            {question.description}
          </Typography>
        }
        action={
          <IconButton 
            onClick={toggleVisibility} 
            size="small" 
            aria-label={contentVisible ? "Hide content" : "Show content"}
            title={contentVisible ? "Hide content" : "Show content"}
          >
            {contentVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        }
        sx={{ 
          bgcolor: theme.palette.grey[50],
          borderBottom: contentVisible ? `1px solid ${theme.palette.divider}` : 'none'
        }}
      />
      
      <Collapse in={contentVisible}>
        <CardContent sx={{ p: 0 }}>
          <Box p={2} sx={{ 
            // Improve styling for overflow content
            '& .MuiTypography-root': { 
              wordBreak: 'break-word', // Allow words to break to prevent overflow
              overflowWrap: 'break-word'
            },
            // Add horizontal scrolling for really long content
            '& .MuiBox-root': {
              maxWidth: '100%',
              overflowX: 'auto'
            },
            // Ensure emails don't overflow
            '& .MuiChip-root': {
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }}>
            {/* Select visualization based on question type */}
            {question.type === 'select' || question.type === 'radio' || question.type === 'multi-select' ? (
              <SelectVisualization 
                question={question} 
                summaryData={summaryData}
                filteredSubmissions={effectiveSubmissions}
                onSegmentClick={handleSelectFilterChange}
                activeFilter={activeFilter}
              />
            ) : question.type === 'checkbox' ? (
              <CheckboxVisualization 
                question={question} 
                summaryData={summaryData}
                filteredSubmissions={effectiveSubmissions}
                activeFilter={activeFilter}
              />
            ) : question.type === 'number' || question.type === 'rating' ? (
              <NumberVisualization 
                question={question} 
                summaryData={summaryData}
                filteredSubmissions={effectiveSubmissions}
              />
            ) : question.type === 'date' || question.type === 'date-range' ? (
              <DateVisualization 
                question={question} 
                summaryData={summaryData}
                filteredSubmissions={effectiveSubmissions}
              />
            ) : question.type === 'time' || question.type === 'time-range' ? (
              <TimeVisualization 
                question={question} 
                summaryData={summaryData}
                filteredSubmissions={effectiveSubmissions}
              />
            ) : (question.type === 'text' || question.type === 'textarea' || 
                question.type === 'email' || question.type === 'phone' || 
                question.type === 'name') ? (
              <TextVisualization 
                question={question} 
                summaryData={summaryData}
                filteredSubmissions={effectiveSubmissions}
              />
            ) : question.type === 'repeatable' ? (
              <RepeatableVisualization 
                question={question} 
                summaryData={summaryData}
                filteredSubmissions={effectiveSubmissions}
              />
            ) : (
              <Typography variant="body2" color="text.secondary" textAlign="center" py={2}>
                No visualization available for question type: {question.type}
              </Typography>
            )}
          </Box>
        </CardContent>
      </Collapse>
      
      {!contentVisible && (
        <Box 
          sx={{ 
            p: 1,
            textAlign: 'center',
            color: 'text.secondary',
            borderTop: `1px dashed ${theme.palette.divider}`
          }}
        >
          <Typography variant="caption">
            Click the eye icon to view {question.type} data
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default QuestionSummary;
