import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, ButtonGroup, Button, Tooltip } from '@mui/material';
import { BarChart, PieChart, CalendarMonth, ViewDay } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { format, differenceInDays, isValid } from 'date-fns';
import { useNormalizedData } from '../VisualizationHelper';
import { TotalDaysVisualization } from '../';

// Define interfaces for our data types
interface DateRange {
  start: Date;
  end: Date;
  email?: string;
  label?: string;
  useHours?: boolean;
  hours?: number | null;
  includeWeekends?: boolean;
}

// Date formatting helper
const formatDate = (date: Date): string => {
  try {
    if (!date || !isValid(date)) return 'Invalid date';
    return format(date, 'MMM d, yyyy');
  } catch (e) {
    console.error('Error formatting date:', e);
    return 'Invalid date';
  }
};

interface DateVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
}

const DateVisualization: React.FC<DateVisualizationProps> = ({ 
  question, 
  summaryData,
  filteredSubmissions = []
}) => {
  const theme = useTheme();
  const normalizedData = useNormalizedData(question, summaryData, filteredSubmissions);
  
  // Check if this is a date range visualization
  const isDateRange = question.type === 'date-range';
  
  // Set default view mode based on question type
  const [viewMode, setViewMode] = useState<'calendar'|'totaldays'>(
    isDateRange ? 'totaldays' : 'calendar'
  );
  
  if (!normalizedData) {
    return (
      <Box textAlign="center" py={2}>
        <Typography variant="body2" color="text.secondary">Loading date data...</Typography>
      </Box>
    );
  }
  
  // For date-range questions, show date ranges with labels
  if (isDateRange) {
    const dateRanges = normalizedData.dateRanges || [];
    
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <ButtonGroup size="small" variant="outlined">
            <Button 
              onClick={() => setViewMode('calendar')}
              variant={viewMode === 'calendar' ? 'contained' : 'outlined'}
              startIcon={<CalendarMonth />}
            >
              Calendar View
            </Button>
            <Button 
              onClick={() => setViewMode('totaldays')}
              variant={viewMode === 'totaldays' ? 'contained' : 'outlined'}
              startIcon={<ViewDay />}
            >
              Total Days
            </Button>
          </ButtonGroup>
        </Box>
        
        {viewMode === 'totaldays' ? (
          <TotalDaysVisualization 
            submissions={filteredSubmissions}
            questionId={question.id}
          />
        ) : (
          <Box>
            {dateRanges.length === 0 ? (
              <Typography variant="body2" color="text.secondary" textAlign="center">
                No date range data available
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {dateRanges.map((range: DateRange, index: number) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Paper 
                      elevation={0} 
                      sx={{ 
                        p: { xs: 1.5, sm: 2 }, 
                        bgcolor: 'background.default',
                        border: '1px solid',
                        borderColor: 'divider',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Tooltip title={range.email || range.label || `Response ${index + 1}`}>
                        <Typography 
                          variant="subtitle2" 
                          sx={{ 
                            fontWeight: 'medium',
                            mb: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {range.email || range.label || `Response ${index + 1}`}
                        </Typography>
                      </Tooltip>
                      
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">From:</Typography>
                            <Typography variant="body2" fontWeight="medium">
                              {formatDate(range.start)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">To:</Typography>
                            <Typography variant="body2" fontWeight="medium">
                              {formatDate(range.end)}
                            </Typography>
                          </Grid>
                        </Grid>
                        
                        <Box sx={{ 
                          mt: 1.5, 
                          pt: 1.5,
                          borderTop: `1px dashed ${theme.palette.divider}`,
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Typography 
                            variant="body2" 
                            color="primary.main" 
                            fontWeight="bold"
                            sx={{
                              px: 2, // Increase horizontal padding
                              py: 0.5,
                              borderRadius: 1,
                              bgcolor: theme.palette.primary.main + '15',
                              width: 'auto', // Let the width adapt to content
                              maxWidth: '100%', // Ensure it doesn't overflow
                              textAlign: 'center'
                            }}
                          >
                            {differenceInDays(range.end, range.start)} days
                            {range.useHours && range.hours !== null && range.hours > 0 && (
                              <Typography 
                                component="span" 
                                variant="body2" 
                                sx={{ 
                                  ml: 0.5,
                                  fontStyle: 'italic',
                                  opacity: 0.8,
                                  color: theme.palette.secondary.main
                                }}
                              >
                                {' '}
                                {range.hours} hours
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        )}
      </Box>
    );
  }
  
  // For single date questions - improved with responsive grid
  const dateResponses = normalizedData.dateResponses || [];
  
  return (
    <Box>
      {dateResponses.length === 0 ? (
        <Typography variant="body2" color="text.secondary" textAlign="center">
          No date data available
        </Typography>
      ) : (
        <Grid container spacing={1}>
          {dateResponses.map((date: Date, index: number) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 1.5, 
                  bgcolor: 'background.default',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  textAlign: 'center'
                }}
              >
                <Typography variant="body2" sx={{ 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                  {formatDate(date)}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default DateVisualization;